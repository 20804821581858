<template>
  <div class="app-select text-grey-500 text-16 capitalize relative">
    <div
      class="wrapper bg-grey-100 flex justify-between p-16 rounded-1 cursor-pointer"
      @click="toggleDropdown"
    >
      <span>{{ selectedOption }}</span>
      <span :class="['arrow', 'text-blue-500', 'font-medium', { 'arrow-open': isOpen }]">
        <img src="/imgs/arrow.svg" class="unfilled" alt="arrow" />
      </span>
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="options absolute bg-grey-100 z-10">
        <ul>
          <li
            v-for="option in options"
            :key="option.value"
            class="option p-16 list-none cursor-pointer hover:bg-grey-200 hover:text-grey-900"
            @click="selectOption(option)"
          >
            {{ option.title }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    preselectedOption: {
      type: Object,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    selectedOptionText: '',
  }),
  computed: {
    selectedOption() {
      if (this.preselectedOption && !this.selectedOptionText) {
        return this.preselectedOption.title
      }

      return this.selectedOptionText || this.placeholder
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },
    selectOption(item) {
      this.selectedOptionText = item.title
      this.toggleDropdown()
      this.$emit('select', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-select {
  user-select: none;

  .wrapper {
    border-bottom: 1px solid theme('colors.grey.500');

    .arrow {
      &-open {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    width: 100%;
    min-height: 36px;
    max-height: 265px;
    box-shadow: 0 3px 4px 0 theme('colors.grey.200');
    overflow-y: scroll;

    .option {
      border-bottom: 1px solid theme('colors.grey.400');

      &:last-child {
        border: 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
