<template>
  <div class="app-horizontal-radio-button-group">
    <div class="radio-buttons-wrapper flex justify-around">
      <app-radio-button
        v-for="(option, index) in options"
        :key="index"
        :radio-button-data="option"
        @click="radioButtonBehaviourSimulator"
      />
    </div>
  </div>
</template>

<script>
import AppRadioButton from '@/components/form/AppRadioButton'

export default {
  name: 'AppHorizontalRadioButtonGroup',
  components: {
    AppRadioButton,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    previousIndex: undefined,
  }),
  mounted() {
    this.previousIndex = this.options.findIndex((option) => option.model)
  },
  methods: {
    radioButtonBehaviourSimulator(radioValue) {
      let radioButtonOptionsCopy = this.options

      const checkIndex = radioButtonOptionsCopy.findIndex((type) => type.name === radioValue.name)
      radioButtonOptionsCopy = radioButtonOptionsCopy.map((value) => {
        value.model = false
        return value
      })

      radioButtonOptionsCopy[checkIndex] = radioValue

      if (checkIndex !== this.previousIndex) {
        this.$emit('change')
        this.previousIndex = checkIndex
      }
    },
  },
}
</script>
