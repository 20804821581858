export function spanishIdCards(idCard) {
  if (!idCard) {
    return true
  }
  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  var str = idCard.toString().toUpperCase()

  if (!nifRexp.test(str) && !nieRexp.test(str)) return false

  var nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2')

  var letter = str.substr(-1)
  var charIndex = parseInt(nie.substr(0, 8)) % 23

  if (validChars.charAt(charIndex) === letter) return true

  return false
}
