<template>
  <div class="personal-data-form flex flex-col w-full">
    <h1 class="title ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.TITLE') }}
    </h1>
    <form class="form mt-33 flex-auto" @submit.prevent>
      <!-- NAME -->
      <div class="name-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="name"
            :error="$v.name.$invalid && $v.name.$dirty"
            :maxlength="20"
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NAME.PLACEHOLDER')"
            @input="$v.name.$touch()"
          />
          <p
            v-if="$v.name.$invalid && $v.name.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NAME.ERROR') }}
          </p>
          <p v-if="expressionRegularName" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
        </div>
      </div>

      <!-- FIRST_SURNAME -->
      <div class="first-surname-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FIRST_SURNAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="firstSurname"
            :error="$v.firstSurname.$invalid && $v.firstSurname.$dirty"
            :maxlength="30"
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FIRST_SURNAME.PLACEHOLDER')"
            @input="$v.firstSurname.$touch()"
          />
          <p
            v-if="$v.firstSurname.$invalid && $v.firstSurname.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FIRST_SURNAME.ERROR') }}
          </p>
          <p v-if="expressionRegularSurname" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
        </div>
      </div>

      <!-- SECOND_SURNAME -->
      <div class="second-surname-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SECOND_SURNAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="secondSurname"
            :error="$v.secondSurname.$invalid && $v.secondSurname.$dirty"
            :maxlength="30"
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SECOND_SURNAME.PLACEHOLDER')"
            @input="$v.secondSurname.$touch()"
          />
          <p
            v-if="$v.secondSurname.$invalid && $v.secondSurname.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SECOND_SURNAME.ERROR') }}
          </p>
          <p v-if="expressionRegularSecondSurname" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
        </div>
      </div>

      <!-- GENRE -->
      <div class="genre-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.GENRE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-horizontal-radio-button-group :options="genre" @change="$v.genre.$touch()" />
          <p v-if="!genreIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.GENRE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- CIVIL STATE -->
      <div class="civil-state-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.CIVIL_STATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.CIVIL_STATE.PLACEHOLDER')"
            :preselected-option="civilState"
            :options="civilStateOptions"
            @select="civilStateSelection"
          />
          <p v-if="$v.civilState.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.CIVIL_STATE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- CIVIL_STATE_DATE -->
      <div class="civil-state-date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.CIVIL_STATE_DATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker :date="civilStateDate" @date-selected="civilStateDateSelected" />
          <p v-if="$v.civilStateDate.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.CIVIL_STATE_DATE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- ID CARD -->
      <div class="id-card-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.ID_CARD.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="idCard"
            :error="$v.idCard.$invalid && $v.idCard.$dirty"
            :maxlength="9"
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.ID_CARD.LABEL')"
            @input="$v.idCard.$touch()"
          />
          <p
            v-if="$v.idCard.$invalid && $v.idCard.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.ID_CARD.ERROR') }}
          </p>
        </div>
      </div>

      <!-- BIRTH_DATE -->
      <div class="birth-date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.BIRTH_DATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker :date="birthDate" @date-selected="birthDateSelected" />
          <p v-if="$v.birthDate.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.BIRTH_DATE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- NATIONALITY -->
      <div class="nationality-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NATIONALITY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NATIONALITY.PLACEHOLDER')"
            :preselected-option="nationality"
            :options="nationalityOptions"
            @select="nationalitySelection"
          />
          <p v-if="$v.nationality.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.NATIONALITY.ERROR') }}
          </p>
        </div>
      </div>

      <!-- FISCAL RESIDENCE -->
      <div class="fiscal-residence-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FISCAL_RESIDENCE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-select
            :placeholder="
              $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FISCAL_RESIDENCE.PLACEHOLDER')
            "
            :preselected-option="fiscalResidence"
            :options="fiscalResidenceOptions"
            @select="fiscalResidenceSelection"
          />
          <p v-if="$v.fiscalResidence.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.FISCAL_RESIDENCE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- HANDICAP -->
      <div class="handicap-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-horizontal-radio-button-group :options="handicap" @change="$v.handicap.$touch()" />
          <p v-if="!handicapIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.ERROR') }}
          </p>
        </div>
      </div>

      <!-- EXTENDED HANDICAP -->
      <div
        v-if="extendedHandicapIsVisible"
        class="handicap-extended-info bg-grey-100 form-row-wrapper p-32 mt-40 rounded-1 ml-68 mb-40 mr-28"
      >
        <!-- WITH HELP -->
        <div class="handicap-extended-with-help-field form-row-wrapper md:flex mb-40">
          <div class="flex-auto w-1/3">
            <label class="text-16 text-gray-900">
              {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.HELP.LABEL') }}
            </label>
          </div>
          <div class="w-1/3 max-w-md flex-auto">
            <app-horizontal-radio-button-group
              :options="handicapExtendedInfo"
              @change="$v.handicapExtendedInfo.$touch()"
            />
            <p v-if="!extendedHandicapIsValid" class="error-message text-red-500 text-15 mt-12">
              {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.HELP.ERROR') }}
            </p>
          </div>
        </div>

        <!-- PERCENTAGE -->
        <div class="handicap-extended-percentage-field form-row-wrapper md:flex">
          <div class="flex-auto w-1/3">
            <label class="text-16 text-gray-900">
              {{
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.LABEL'
                )
              }}
            </label>
          </div>
          <div class="w-1/3 max-w-md flex-auto">
            <app-input
              v-model.number="percentage"
              type="number"
              class="bg-white"
              :maxlength="3"
              :placeholder="
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.PLACEHOLDER'
                )
              "
              @input="$v.percentage.$touch()"
            />
            <p
              v-if="$v.percentage.$invalid || percentageIsRequired"
              class="error-message text-red-500 text-15 mt-12"
            >
              {{
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.ERROR'
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- SOCIAL SECURITY -->
      <div class="social-security-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SOCIAL_SECURITY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="socialSecurity"
            :chars="/[0-9]/"
            :error="
              ($v.socialSecurity.$invalid || !socialSecurityValid) && $v.socialSecurity.$dirty
            "
            :minlength="11"
            :maxlength="12"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SOCIAL_SECURITY.PLACEHOLDER')
            "
            @input="$v.socialSecurity.$touch()"
          />
          <p
            v-if="($v.socialSecurity.$invalid || !socialSecurityValid) && $v.socialSecurity.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.SOCIAL_SECURITY.ERROR') }}
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators'
import { spanishIdCards } from '@/shared/utils/idCardsValidator'
import AppInput from '@/components/form/AppInput'
import AppSelect from '@/components/form/AppSelect'
import AppHorizontalRadioButtonGroup from '@/components/form/AppHorizontalRadioButtonGroup'
import AppDatepicker from '@/components/form/AppDatepicker'

const idCardValidator = (idCard) => spanishIdCards(idCard)

export default {
  name: 'PersonalForm',
  components: {
    AppInput,
    AppSelect,
    AppHorizontalRadioButtonGroup,
    AppDatepicker,
  },
  mixins: [validationMixin],
  data: () => ({
    status: undefined,
    integrationName: 'seniorDatosS1VO',
    name: '',
    firstSurname: '',
    secondSurname: '',
    genre: [],
    civilState: {},
    civilStateOptions: [],
    civilStateDate: undefined,
    idCard: '',
    birthDate: undefined,
    nationality: undefined,
    nationalityOptions: [],
    fiscalResidence: undefined,
    fiscalResidenceOptions: [],
    fiscalResidenceOld: undefined,
    handicap: [],
    handicapExtendedInfo: [],
    percentage: '',
    socialSecurity: '',
  }),
  computed: {
    ...mapState('personalData', ['assembledPersonalData']),
    genreIsValid() {
      return !!this.genre.find((option) => option.model === true)
    },
    handicapIsValid() {
      return !!this.handicap.find((option) => option.model === true)
    },
    extendedHandicapIsVisible() {
      if (!this.handicap[0] || !this.handicap[0].model) {
        return false
      }

      return !!this.handicap[0].model
    },
    extendedHandicapIsValid() {
      if (!this.handicapIsValid) {
        return false
      }

      return !!this.handicapExtendedInfo.find((option) => option.model === true)
    },
    extendedHandicapNeedsValidation() {
      return this.extendedHandicapIsVisible
    },
    percentageIsRequired() {
      if (this.handicap[0].model && this.percentage === 0) return true

      return false
    },
    percentageIsValid() {
      if ((this.percentage === 0 || this.percentage === '') && this.handicap[1].model === false) {
        return false
      }
      return true
    },
    socialSecurityValid() {
      let num = this.socialSecurity.replace(/[^0-9]/g, '')
      const len = num.length

      if (len < 11 || len > 12) {
        return false
      }

      if (num.substr(2, 1) == 0) {
        num = num.substr(0, 2) + num.substr(3)
      }

      return num.substr(0, len - 3) % 97 === Number(num.substr(-2))
    },
    formName() {
      return this.$t('PERSONAL_DATA.FORMS.PERSONAL_DATA.TITLE')
    },
    expressionRegularName() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.name)
      return res
    },
    expressionRegularSurname() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.firstSurname)
      return res
    },
    expressionRegularSecondSurname() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.secondSurname)
      return res
    },
    isValid() {
      if (!this.canEdit) {
        return false
      }

      const extendenHandicapIsValid = this.extendedHandicapNeedsValidation
        ? this.extendedHandicapIsValid
        : true

      return (
        !this.$v.$invalid &&
        this.genreIsValid &&
        !!this.civilState &&
        !!this.civilStateDate &&
        !!this.birthDate &&
        !!this.nationality &&
        !!this.fiscalResidence &&
        !!extendenHandicapIsValid &&
        this.handicapIsValid &&
        this.percentageIsValid &&
        this.socialSecurityValid
      )
    },
    canEdit() {
      const canEditFormCase1 = this.status === '2'
      const canEditFormCase2 = this.status === '3'
      const canEditFormCase3 = this.status === null

      return canEditFormCase1 || canEditFormCase2 || canEditFormCase3
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(20),
    },
    firstSurname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(30),
    },
    secondSurname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(30),
    },
    genre: {},
    idCard: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
      idCardValidator,
    },
    civilState: {
      required,
    },
    civilStateDate: {
      required,
    },
    birthDate: {
      required,
    },
    nationality: {
      required,
    },
    fiscalResidence: {
      required,
    },
    handicap: {
      required,
    },
    percentage: {
      minLength: minLength(1),
      maxLength: maxLength(3),
      between: between(0, 100),
    },
    socialSecurity: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
  },
  mounted() {
    window.scrollTo(0, 0), this.localAssembler()
  },
  beforeDestroy() {
    this.emptyMessages()
  },
  methods: {
    ...mapMutations('personalData', ['setPersonalDataFormRawDataToCMS']),
    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),
    localAssembler() {
      const deepCopyPersonalData = JSON.parse(JSON.stringify(this.assembledPersonalData))
      this.name = this.assembledPersonalData.name
      this.firstSurname = this.assembledPersonalData.firstSurname
      this.secondSurname = this.assembledPersonalData.secondSurname
      this.genre = deepCopyPersonalData.genre
      this.civilState = this.assembledPersonalData.civilState
      this.civilStateOptions = this.assembledPersonalData.civilStateOptions
      this.civilStateDate = this.assembledPersonalData.civilStateDate.date
      this.idCard = this.assembledPersonalData.idCard
      this.birthDate = this.assembledPersonalData.birthDate.date
      this.nationality = this.assembledPersonalData.nationality
      this.nationalityOptions = this.assembledPersonalData.nationalityOptions
      this.fiscalResidence = this.assembledPersonalData.fiscalResidence
      this.fiscalResidenceOptions = this.assembledPersonalData.fiscalResidenceOptions
      this.fiscalResidenceOld = this.fiscalResidence.value
      this.handicap = deepCopyPersonalData.handicap.values
      this.handicapExtendedInfo = deepCopyPersonalData.handicapExtended
      this.percentage = this.assembledPersonalData.handicap.extended.percentage
      this.socialSecurity = this.assembledPersonalData.socialSecurity
      this.status = this.assembledPersonalData.status

      if (!this.canEdit) {
        this.addMessage({
          type: 'success',
          text: 'Ya has grabado datos para esta sección',
        })
      }
    },
    sendDataToAssembler() {
      this.setPersonalDataFormRawDataToCMS({
        name: this.name,
        firstSurname: this.firstSurname,
        secondSurname: this.secondSurname,
        idCard: this.idCard,
        nationality: this.nationality,
        genre: this.genre,
        birthDate: this.birthDate,
        fiscalResidence: this.fiscalResidence,
        fiscalResidenceOld: this.fiscalResidenceOld,
        socialSecurity: this.socialSecurity,
        handicap: this.handicap,
        handicapExtended: this.handicapExtendedInfo,
        percentage: this.percentage,
        civilState: this.civilState,
        civilStateDate: this.civilStateDate,
      })
    },
    civilStateSelection(selectedOption) {
      this.civilState = selectedOption
      this.$v.civilState.$touch()
    },
    nationalitySelection(selectedOption) {
      this.nationality = selectedOption
      this.$v.nationality.$touch()
    },
    fiscalResidenceSelection(selectedOption) {
      this.fiscalResidence = selectedOption
      this.$v.fiscalResidence.$touch()
    },
    civilStateDateSelected(date) {
      this.civilStateDate = date
      this.$v.civilStateDate.$touch()
    },
    birthDateSelected(date) {
      this.birthDate = date
      this.$v.birthDate.$touch()
    },
    save() {
      const formDataResponse = {
        formName: this.formName,
        integrationName: this.integrationName,
        isValid: this.isValid,
        canEdit: this.canEdit,
        isAnyDirty: this.$v.$anyDirty,
      }

      this.sendDataToAssembler()
      return formDataResponse
    },
  },
}
</script>

<style lang="scss" scoped>
.personal-data-form {
  .form {
    .handicap-field {
      // stylelint-disable-next-line selector-max-compound-selectors
      .handicap-extended-info {
        width: 100%;
        box-shadow: 0 2px 3px 1px theme('colors.grey.200');
      }
    }
  }
}
</style>
