<template>
  <div class="personal-data-form flex flex-col w-full">
    <h1 class="title ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('PERSONAL_DATA.FORMS.CONTACT.TITLE') }}
    </h1>
    <form class="form mt-56 flex-auto" @submit.prevent>
      <!-- BLOCK 1 -->
      <h1 class="title ml-68 pb-40 flex-auto text-grey-900 text-18 font-semibold">
        {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.TITLE') }}
      </h1>

      <!-- POSTAL ADDRESS -->
      <div class="postal-address-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_ADDRESS.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="postalAddress"
            :error="$v.postalAddress.$invalid && $v.postalAddress.$dirty"
            :maxlength="75"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_ADDRESS.PLACEHOLDER')
            "
            @input="$v.postalAddress.$touch()"
          />
          <p
            v-if="$v.postalAddress.$invalid && $v.postalAddress.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_ADDRESS.ERROR') }}
          </p>
        </div>
      </div>

      <!-- MUNICIPALITY -->
      <div class="municipality-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.MUNICIPALITY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="municipality"
            :error="$v.municipality.$invalid && $v.municipality.$dirty"
            :maxlength="40"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.MUNICIPALITY.PLACEHOLDER')"
            @input="$v.municipality.$touch()"
          />
          <p
            v-if="$v.municipality.$invalid && $v.municipality.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.MUNICIPALITY.ERROR') }}
          </p>
        </div>
      </div>

      <!-- POSTAL CODE -->
      <div class="postal-code-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_CODE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="postalCode"
            :error="$v.postalCode.$invalid && $v.postalCode.$dirty"
            :maxlength="15"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_CODE.PLACEHOLDER')"
            @input="$v.postalCode.$touch()"
          />
          <p
            v-if="$v.postalCode.$invalid && $v.postalCode.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTAL_CODE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- Postal Code Country -->
      <div class="postalCodeCountry-field form-row-wrapper md:flex mb-72">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTALCODECOUNTRY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-select
            :placeholder="
              $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTALCODECOUNTRY.PLACEHOLDER')
            "
            :preselected-option="postalCodeCountry"
            :options="postalCodeCountryOptions"
            @select="postalCodeCountrySelection"
          />
          <p v-if="$v.postalCodeCountry.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK1.FIELDS.POSTALCODECOUNTRY.ERROR') }}
          </p>
        </div>
      </div>

      <!-- BLOCK 2 -->
      <h1 class="title ml-68 pb-40 flex-auto text-grey-900 text-18 font-semibold">
        {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.TITLE') }}
      </h1>

      <!-- FISCAL ADDRESS -->
      <div class="fiscal-address-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.FISCAL_ADDRESS.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="fiscalAddress"
            :error="$v.fiscalAddress.$invalid && $v.fiscalAddress.$dirty"
            :maxlength="75"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.FISCAL_ADDRESS.PLACEHOLDER')
            "
            @input="$v.fiscalAddress.$touch()"
          />
          <p
            v-if="$v.fiscalAddress.$invalid && $v.fiscalAddress.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.FISCAL_ADDRESS.ERROR') }}
          </p>
        </div>
      </div>

      <!-- MUNICIPALITY -->
      <div class="fiscal-municipality-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MUNICIPALITY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="fiscalMunicipality"
            :error="$v.fiscalMunicipality.$invalid && $v.fiscalMunicipality.$dirty"
            :maxlength="40"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MUNICIPALITY.PLACEHOLDER')"
            @input="$v.fiscalMunicipality.$touch()"
          />
          <p
            v-if="$v.fiscalMunicipality.$invalid && $v.fiscalMunicipality.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MUNICIPALITY.ERROR') }}
          </p>
        </div>
      </div>

      <!-- FISCAL POSTAL CODE -->
      <div class="fiscal-postal-code-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.POSTAL_CODE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="fiscalPostalCode"
            :error="$v.fiscalPostalCode.$invalid && $v.fiscalPostalCode.$dirty"
            :maxlength="15"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.POSTAL_CODE.PLACEHOLDER')"
            @input="$v.fiscalPostalCode.$touch()"
          />
          <p
            v-if="$v.fiscalPostalCode.$invalid && $v.fiscalPostalCode.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.POSTAL_CODE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- Postal Code Country -->
      <div class="codeFiscalCountry-field form-row-wrapper md:flex mb-114">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.CODEFISCALCOUNTRY.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-select
            :placeholder="
              $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.CODEFISCALCOUNTRY.PLACEHOLDER')
            "
            :preselected-option="codeFiscalCountry"
            :options="codeFiscalCountryOptions"
            @select="codeFiscalCountrySelection"
          />
          <p v-if="$v.codeFiscalCountry.$invalid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.CODEFISCALCOUNTRY.ERROR') }}
          </p>
        </div>
      </div>
      <!-- EMAIL -->
      <div class="email-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.EMAIL.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="email"
            :maxlength="99"
            :error="$v.email.$invalid && $v.email.$dirty"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.EMAIL.PLACEHOLDER')"
            @input="$v.email.$touch()"
          />
          <p
            v-if="$v.email.$invalid && $v.email.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.EMAIL.ERROR') }}
          </p>
        </div>
      </div>

      <!-- PHONE -->
      <div class="phone-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.PHONE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="phone"
            :error="$v.phone.$invalid && $v.phone.$dirty"
            :maxlength="99"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.PHONE.PLACEHOLDER')"
            @input="$v.phone.$touch()"
          />
          <p
            v-if="$v.phone.$invalid && $v.phone.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.PHONE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- MOVILE PHONE -->
      <div class="movile-phone-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MOVILE_PHONE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="movilePhone"
            :error="$v.movilePhone.$invalid && $v.movilePhone.$dirty"
            :maxlength="99"
            :placeholder="$t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MOVILE_PHONE.PLACEHOLDER')"
            @input="$v.movilePhone.$touch()"
          />
          <p
            v-if="$v.movilePhone.$invalid && $v.movilePhone.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.CONTACT.BLOCK2.FIELDS.MOVILE_PHONE.ERROR') }}
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength, numeric, email } from 'vuelidate/lib/validators'
import AppInput from '@/components/form/AppInput'
import AppSelect from '@/components/form/AppSelect'
import { emailFormatter } from '@/shared/utils/emailFormatter'

export default {
  name: 'ContactForm',
  components: {
    AppInput,
    AppSelect,
  },
  mixins: [validationMixin],

  data: () => ({
    integrationName: 'seniorDatosS2VO',
    postalAddress: '',
    municipality: '',
    postalCodeCountry: undefined,
    postalCodeCountryOptions: [],
    postalCode: '',
    fiscalAddress: '',
    fiscalMunicipality: '',
    codeFiscalCountry: undefined,
    codeFiscalCountryOptions: [],
    codeFiscalCountryOld: undefined,
    fiscalPostalCode: '',
    email: '',
    phone: '',
    movilePhone: '',
    status: undefined,
  }),
  computed: {
    ...mapState('personalData', ['assembledContactData']),
    formName() {
      return this.$t('PERSONAL_DATA.FORMS.CONTACT.TITLE')
    },
    isValid() {
      if (!this.canEdit) {
        return false
      }
      return !this.$v.$invalid && !!this.postalCodeCountry && !!this.codeFiscalCountry
    },
    canEdit() {
      const canEditFormCase1 = this.status === '2'
      const canEditFormCase2 = this.status === '3'
      const canEditFormCase3 = this.status === null

      return canEditFormCase1 || canEditFormCase2 || canEditFormCase3
    },
  },
  watch: {
    assembledContactData() {
      this.localAssembler()
    },
  },
  validations: {
    postalAddress: {
      required,
      maxLength: maxLength(75),
    },
    municipality: {
      required,
      maxLength: maxLength(40),
    },
    postalCodeCountry: {
      required,
    },
    postalCode: {
      required,
      numeric,
      maxLength: maxLength(15),
    },
    fiscalAddress: {
      required,
      maxLength: maxLength(75),
    },
    fiscalMunicipality: {
      required,
      maxLength: maxLength(40),
    },
    codeFiscalCountry: {
      required,
    },
    fiscalPostalCode: {
      required,
      numeric,
      maxLength: maxLength(15),
    },
    email: {
      email: (value) => email(emailFormatter(value)),
    },
    phone: {
      numeric,
    },
    movilePhone: {
      numeric,
    },
  },
  mounted() {
    window.scrollTo(0, 0), this.localAssembler()
  },
  beforeDestroy() {
    this.emptyMessages()
  },
  methods: {
    ...mapMutations('personalData', ['setContactDataFormRawDataToCMS']),
    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),
    localAssembler() {
      this.postalAddress = this.assembledContactData.postalAddress
      this.municipality = this.assembledContactData.municipality
      this.postalCodeCountry = this.assembledContactData.postalCodeCountry
      this.postalCodeCountryOptions = this.assembledContactData.postalCodeCountryOptions
      this.postalCode = this.assembledContactData.postalCode
      this.fiscalAddress = this.assembledContactData.fiscalAddress
      this.fiscalMunicipality = this.assembledContactData.fiscalMunicipality
      this.codeFiscalCountry = this.assembledContactData.codeFiscalCountry
      this.codeFiscalCountryOptions = this.assembledContactData.codeFiscalCountryOptions
      this.codeFiscalCountryOld = this.codeFiscalCountry.value
      this.fiscalPostalCode = this.assembledContactData.fiscalPostalCode
      this.email = this.assembledContactData.email
      this.phone = this.assembledContactData.phone
      this.movilePhone = this.assembledContactData.movilePhone
      this.status = this.assembledContactData.status
      this.$v.$reset()

      if (!this.canEdit) {
        this.addMessage({
          type: 'success',
          text: 'Ya has grabado datos para esta sección!',
        })
      }
    },
    sendDataToAssembler() {
      this.setContactDataFormRawDataToCMS({
        postalAddress: this.postalAddress,
        municipality: this.municipality,
        postalCodeCountry: this.postalCodeCountry.value,
        postalCode: this.postalCode,
        fiscalAddress: this.fiscalAddress,
        codeFiscalCountry: this.codeFiscalCountry.value,
        fiscalMunicipality: this.fiscalMunicipality,
        fiscalPostalCode: this.fiscalPostalCode,
        codeFiscalCountryOld: this.codeFiscalCountryOld,
        email: this.email,
        phone: this.phone,
        movilePhone: this.movilePhone,
      })
    },
    postalCodeCountrySelection(selectedOption) {
      this.postalCodeCountry = selectedOption
      this.$v.postalCodeCountry.$touch()
    },
    codeFiscalCountrySelection(selectedOption) {
      this.codeFiscalCountry = selectedOption
      this.$v.codeFiscalCountry.$touch()
    },
    save() {
      const formDataResponse = {
        formName: this.formName,
        integrationName: this.integrationName,
        isValid: this.isValid,
        canEdit: this.canEdit,
        isAnyDirty: this.$v.$anyDirty,
      }

      this.sendDataToAssembler()
      return formDataResponse
    },
  },
}
</script>
