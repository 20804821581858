<template>
  <div class="app-radio-button">
    <label
      class="container block relative pl-32 mb-12 select-none text-16 cursor-pointer text-gray-900"
    >
      {{ radioButtonData.name }}
      <input
        v-model="radioButtonData.model"
        class="absolute opacity-0 cursor-pointer"
        :name="radioButtonData.name"
        type="checkbox"
        checked="checked"
        @input="toggleRadio"
      />
      <span class="checkmark absolute top-0 left-0"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppRadioButton',
  props: {
    radioButtonData: {
      type: Object,
      default: new Object(),
    },
  },
  methods: {
    toggleRadio() {
      this.$emit('click', this.radioButtonData)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-radio-button {
  .container {
    .checkmark {
      width: 25px;
      height: 25px;
      border: 1px solid theme('colors.grey.900');
      background-color: theme('colors.grey.100');
      border-radius: 50%;

      &::after {
        position: absolute;
        top: 8px;
        left: 8px;
        display: none;
        width: 7px;
        height: 7px;
        background: theme('colors.blue.500');
        border-radius: 50%;
        content: '';
      }
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    input:checked ~ .checkmark {
      background-color: theme('colors.white');
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    &:hover input ~ .checkmark {
      background-color: theme('colors.blue.500');
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    &:hover input:checked ~ .checkmark {
      background-color: theme('colors.blue.500');
    }

    &:hover .checkmark::after {
      background: theme('colors.white');
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    input:checked ~ .checkmark::after {
      display: block;
    }
  }
}
</style>
