<template>
  <div class="personal-data-documents-list">
    <ul>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PERSONAL_DATA.OPTION_1') }}
      </li>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PERSONAL_DATA.OPTION_2') }}
      </li>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PERSONAL_DATA.OPTION_3') }}
      </li>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PERSONAL_DATA.OPTION_4') }}
      </li>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PERSONAL_DATA.OPTION_5') }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PersonalDataDocumentList',
}
</script>

<style lang="scss" scoped>
.personal-data-documents-list {
  width: 90%;
  padding: 0 5%;

  ul {
    list-style: disc;

    li {
      margin-bottom: 10px;
    }
  }
}
</style>
