<template>
  <div class="family-card bg-grey-100 flex flex-col w-full px-32 py-24">
    <div class="header flex w-full mb-24 items-baseline justify-between">
      <h2 class="text-18 font-semibold">{{ title }}</h2>
      <div v-if="!isFormDisplay" class="options text-blue-500 font-semibold">
        <span v-if="canEdit" class="cursor-pointer" @click="edit">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.CARD_ACTIONS.EDIT')
        }}</span>
      </div>
      <div v-if="isFormDisplay" class="options text-blue-500 font-semibold">
        <span class="pl-15 cursor-pointer" @click="cancel">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.CARD_ACTIONS.CANCEL')
        }}</span>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FamilyCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    isFormDisplay: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.family-card {
  min-height: 309px;

  .header {
    height: 53px;
    border-bottom: 2px solid theme('colors.grey.400');
  }

  .has-separator {
    border-right: 2px solid theme('colors.grey.400');
  }
}
</style>
