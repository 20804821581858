<template>
  <div class="familiar-summary flex flex-col w-full">
    <div class="row">
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.RELATIONSHIP')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.summaryRelationship }}</span>
      </div>
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.SEX')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.summaryGenre }}</span>
      </div>
    </div>
    <div class="row">
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.BIRTH_DATE')
        }}</strong>
        <span class="element-text text-grey-500">{{ formattedBirthDateDate }}</span>
      </div>
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.CIVIL_STATE')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.summaryCivilState }}</span>
      </div>
    </div>

    <div class="row">
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.RELATIONSHIP_START_DATE')
        }}</strong>
        <span class="element-text text-grey-500">{{ formattedrelationshipStartDate }}</span>
      </div>
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.ID_CARD')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.idCard }}</span>
      </div>
    </div>

    <div class="row">
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.COMPUTE')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.isComputing.title }}</span>
      </div>
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.WORK')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.summaryIsWorking }}</span>
      </div>
    </div>

    <div class="row">
      <div class="row-element mb-16">
        <strong class="element-title mr-8">{{
          $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.SUMMARY.HANDICAP')
        }}</strong>
        <span class="element-text text-grey-500">{{ summary.summaryHandicap }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { DateFormatter } from '@/shared/utils/dateFormatter'

export default {
  name: 'FamiliarSummary',
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedBirthDateDate() {
      if (this.summary.birthDate.dateControl) {
        return DateFormatter.formatDateInSpanish(this.summary.birthDate.dateControl)
      } else {
        return DateFormatter.formatDateInSpanish(this.summary.birthDate.date)
      }
    },
    formattedrelationshipStartDate() {
      if (this.summary.relationshipStartDate.dateControl) {
        return DateFormatter.formatDateInSpanish(this.summary.relationshipStartDate.dateControl)
      } else {
        return DateFormatter.formatDateInSpanish(this.summary.relationshipStartDate.date)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.familiar-summary {
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
