<template>
  <div class="pension-data-documents-list">
    <ul>
      <li>
        {{ $t('PERSONAL_DATA.FORMS.COMPONENTS.DOCS_LIST.PENSION_DATA.OPTION_1') }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PensionDataDocumentList',
}
</script>

<style lang="scss" scoped>
.pension-data-documents-list {
  width: 90%;
  padding: 0 5%;

  ul {
    list-style: disc;

    li {
      margin-bottom: 10px;
    }
  }
}
</style>
