<template>
  <div class="withholding-form flex flex-auto flex-col w-full">
    <h1 class="title ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('PERSONAL_DATA.FORMS.WITHHOLDING.TITLE') }}
    </h1>
    <form class="form mt-33 flex-auto" @submit.prevent>
      <div class="percentaje-field form-row-wrapper md:flex mb-40">
        <div class="flex w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900 flex-row">
            {{ $t('PERSONAL_DATA.FORMS.WITHHOLDING.FIELD.LABEL') }}
          </label>
          <a class="flex ml-10" href="javascript: void(0)" @click="openWithHoldingModal()">
            <information />
          </a>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="withHolding"
            :error="$v.withHolding.$invalid && $v.withHolding.$dirty"
            :maxlength="3"
            :placeholder="$t('PERSONAL_DATA.FORMS.WITHHOLDING.FIELD.PLACEHOLDER')"
            @input="$v.withHolding.$touch()"
          />
          <p
            v-if="$v.withHolding.$invalid && $v.withHolding.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.WITHHOLDING.FIELD.INPUT_ERROR') }}
          </p>
          <p
            v-if="
              !withHoldingIsValid &&
              !emptyInput &&
              !$v.withHolding.$invalid &&
              $v.withHolding.$dirty
            "
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.WITHHOLDING.FIELD.AMOUNT_ERROR') }}
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators'
import AppInput from '@/components/form/AppInput'
import Information from '@/components/Information'
import Alert from '@/components/modals/Alert'

export default {
  name: 'WithholdingForm',
  components: {
    AppInput,
    Information,
  },
  mixins: [validationMixin],
  data: () => ({
    integrationName: 'seniorDatosS5VO',
    withHolding: '',
    closingPayrollDate: undefined,
    employeeClosingPayrollDate: undefined,
  }),
  computed: {
    ...mapState('personalData', ['assembledWithHoldingsData']),
    formName() {
      return this.$t('PERSONAL_DATA.FORMS.WITHHOLDING.TITLE')
    },
    withHoldingIsValid() {
      if (!this.highThanCurrent || this.withHolding === 0) {
        if (this.isDecember) {
          return true
        }
        return false
      }
      return true
    },
    isDecember() {
      return this.closingPayrollDate.getMonth() === 11
    },
    highThanCurrent() {
      return this.withHolding >= this.assembledWithHoldingsData.percentageRetention
    },
    emptyInput() {
      return this.withHolding === ''
    },
  },
  watch: {
    assembledWithHoldingsData() {
      this.localAssembler()
    },
  },
  validations: {
    withHolding: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(3),
      between: between(0, 100),
    },
  },
  mounted() {
    window.scrollTo(0, 0), this.localAssembler()
  },
  methods: {
    ...mapMutations('personalData', ['setWithHoldingFormRawDataToCMS']),
    localAssembler() {
      this.withHolding = this.assembledWithHoldingsData.percentageRetention
      this.closingPayrollDate = this.assembledWithHoldingsData.closingPayrollDate
      this.employeeClosingPayrollDate = this.assembledWithHoldingsData.employeeClosingPayrollDate

      this.$v.$reset()
    },
    sendDataToAssembler() {
      const data = {
        withHolding: this.withHolding,
      }
      this.setWithHoldingFormRawDataToCMS(data)
    },
    save() {
      if (!this.withHoldingIsValid) {
        return
      }

      const formDataResponse = {
        formName: this.formName,
        integrationName: this.integrationName,
        isValid: !this.$v.$invalid,
        canEdit: true,
        isAnyDirty: this.$v.$anyDirty,
      }

      this.sendDataToAssembler()
      return formDataResponse
    },
    openWithHoldingModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.WITH_HOLDING_INFORMATION.TITLE',
          text: 'MODALS.WITH_HOLDING_INFORMATION.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
  },
}
</script>
