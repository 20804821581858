<template>
  <div class="confirm-save-modal flex flex-col items-center p-64 pt-48 text-grey-900 text-center">
    <img src="/imgs/send-documents.svg" alt="send" />

    <h1 class="mt-40 text-40 leading-125 tracking-048">
      {{ $t('MODALS.CONFIRM_SAVE.TITLE') }}
    </h1>

    <app-button class="mt-48 bg-blue-500 font-bbva px-32 py-16" @click="confirm()">{{
      $t('SHARED.SEND_CHANGES')
    }}</app-button>

    <a
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="close()"
      >{{ $t('SHARED.CANCEL') }}</a
    >
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'ConfirmSave',

  components: {
    AppButton,
  },

  methods: {
    confirm() {
      this.$modal.close(true, {
        confirm: true,
      })
    },
    close() {
      this.$modal.close(true, {
        confirm: false,
      })
    },
  },
}
</script>
