<template>
  <div class="family-container flex flex-col w-full pr-68 pl-68">
    <h1 class="title pb-0 flex-auto text-grey-900 text-24 font-semibold mb-24">
      {{ $t('PERSONAL_DATA.FORMS.FAMILY.TITLE') }}
    </h1>
    <div class="flex justify-end" @click="addNewFamiliar">
      <information
        :class="informationClasses"
        icon="/imgs/add.svg"
        :text="$t('PERSONAL_DATA.FORMS.FAMILY.ADD_NEW_FAMILIAR')"
      />
    </div>
    <div v-for="(familiar, index) in family" :key="index" class="cards-wrapper">
      <family-card
        v-if="familiar.familiarNumber && familiar.action !== '1'"
        class="mb-40"
        :title="buildCardTitle(familiar)"
        :can-edit="!familiarFormIsVisible"
        @edit="editFamiliar(familiar)"
      >
        <familiar-summary :summary="familiar" />
      </family-card>
    </div>
    <div v-if="familiarFormIsVisible" class="cards-wrapper">
      <family-card class="mb-40" :title="addFamiliar" :is-form-display="true" @cancel="hideForm">
        <familiar-form
          :senior-birth-date="senior"
          :senior-civil-state="seniorCivilState"
          :family="family"
          :familiar="familiarToInteract"
          :is-new-familiar="isNewFamiliar"
          :master-genres="masterGenres"
          :master-relationships="masterRelationships"
          :master-civil-states="masterCivilStates"
          :master-work-categories="masterWorkCategories"
          :master-is-computing="masterIsComputing"
          @add-familiar="onAddFamiliar"
        />
      </family-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import FamilyCard from '@/views/personal-data/components/family/FamilyCard'
import FamiliarSummary from './FamiliarSummary'
import FamiliarForm from './FamiliarForm'
import { validationMixin } from 'vuelidate'
import ConfirmSave from '@/components/modals/ConfirmSave'
import Information from '@/components/Information'
import FamilyDataCMSAssembler from '@/views/personal-data/helpers/familyDataCMSAssembler'

export default {
  name: 'FamilyForm',
  components: {
    FamilyCard,
    FamiliarSummary,
    FamiliarForm,
    Information,
  },
  mixins: [validationMixin],
  data: () => ({
    status: undefined,
    integrationName: 'seniorDatosS3VO',
    familiarFormIsVisible: false,
    family: undefined,
    familiarToInteract: undefined,
    isNewFamiliar: false,
    masterRelationships: undefined,
    masterNationalities: undefined,
    masterCivilStates: undefined,
    masterGenres: undefined,
    masterWorkCategories: undefined,
    masterIsComputing: undefined,
  }),
  computed: {
    ...mapState('personalData', ['assembledFamilyData']),
    informationClasses() {
      return {
        'mb-24': true,
        'cursor-pointer': !this.familiarFormIsVisible,
        'opacity-50': this.familiarFormIsVisible,
      }
    },
    addFamiliar() {
      // prettier-ignore
      return `${this.$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.TITLE')} ${this.family.length + 1}`
    },
    formName() {
      return this.$t('PERSONAL_DATA.FORMS.FAMILY.TITLE')
    },
    canEdit() {
      const canEditFormCase1 = this.status === '2'
      const canEditFormCase2 = this.status === '3'
      const canEditFormCase3 = this.status === null

      return canEditFormCase1 || canEditFormCase2 || canEditFormCase3
    },
  },
  validations: {},
  created() {
    this.reset()
  },
  mounted() {
    window.scrollTo(0, 0), this.localAssembler()
  },
  beforeDestroy() {
    this.emptyMessages()
  },
  methods: {
    ...mapMutations('personalData', ['setFamilyDataFormRawDataToCMS']),
    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),
    deepFamilyCopyBuilder(family) {
      const familyToReturn = family.map((familiar, index) => {
        familiar.birthDate = this.assembledFamilyData.family[index].birthDate
        familiar.relationshipStartDate = this.assembledFamilyData.family[
          index
        ].relationshipStartDate
        familiar.deathDate = this.assembledFamilyData.family[index].deathDate
        return familiar
      })
      return familyToReturn
    },
    localAssembler() {
      const deepCopyFamilyData = JSON.parse(JSON.stringify(this.assembledFamilyData.family))
      this.family = this.deepFamilyCopyBuilder(deepCopyFamilyData)
      this.status = this.assembledFamilyData.status
      this.masterGenres = this.assembledFamilyData.genreOptions
      this.masterRelationships = this.assembledFamilyData.relationshipOptions
      this.masterCivilStates = this.assembledFamilyData.civilStateOptions
      this.masterWorkCategories = this.assembledFamilyData.workCategoryOptions
      this.masterIsComputing = this.assembledFamilyData.isComputingOptions
      this.senior = this.assembledFamilyData.userBirthDate
      this.seniorCivilState = this.assembledFamilyData.userCivilState

      if (!this.canEdit) {
        this.addMessage({
          type: 'success',
          text: 'Ya has grabado datos para esta sección!',
        })
      }
    },
    openConfirmSave() {
      const options = {
        maskClass: 'bg-mask',
      }
      this.$modal.open(ConfirmSave, {}, options).then((response) => {
        response.confirm && this.save(true)
      })
    },
    reset() {},
    sendDataToAssembler() {
      this.setFamilyDataFormRawDataToCMS(FamilyDataCMSAssembler.assemble(this.family))
    },
    save() {
      const formDataResponse = {
        formName: this.formName,
        integrationName: this.integrationName,
        isValid: !this.$v.$invalid,
        canEdit: this.canEdit,
        isAnyDirty: this.$v.$anyDirty,
        formIsOpen: this.familiarFormIsVisible,
      }

      if (formDataResponse.isAnyDirty) {
        this.sendDataToAssembler()
      }

      return formDataResponse
    },
    editFamiliar(familiar) {
      familiar.action = '5'
      this.isNewFamiliar = false
      this.familiarFormIsVisible = true
      this.isEditingACurrentFamiliar = true
      this.familiarToInteract = familiar
      this.$v.$touch()
    },
    addNewFamiliar() {
      this.isNewFamiliar = true
      this.familiarToInteract = {}
      this.familiarFormIsVisible = true
      this.$v.$touch()
    },
    hideForm() {
      this.familiarFormIsVisible = false
    },
    buildCardTitle(familiar) {
      if (!familiar) {
        return
      }

      return `${familiar.name} ${familiar.firstSurname} ${familiar.secondSurname}`
    },
    onAddFamiliar(familiar) {
      const familiarIndex = this.family.findIndex(
        (familiarMember) => familiarMember.familiarNumber === familiar.familiarNumber
      )

      if (familiarIndex === -1) {
        // THIS IS CREATION
        familiar.action = '3'
        this.family.push(familiar)
        this.familiarFormIsVisible = false
        return
      } else {
        // THIS IS EDIT
        familiar.action = '5'
      }
      this.$set(this.family, familiarIndex, familiar)
      this.familiarFormIsVisible = false
    },
  },
}
</script>
