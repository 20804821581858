<template>
  <div class="pension-form flex flex-col w-full">
    <h1 class="title ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('PERSONAL_DATA.FORMS.PENSION.TITLE') }}
    </h1>
    <h3 class="section-title ml-68 pb-0 mt-40 flex-auto text-grey-900 text-18 font-semibold">
      {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.TITLE_SPOUSE') }}
    </h3>
    <form class="form mt-33 flex-auto" @submit.prevent>
      <!-- DATE SPOUSE -->
      <div class="date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.DATE_SPOUSE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker
            :date="pensionDateSpouse"
            :is-blank="isBlankSpouse"
            @date-selected="pensionDateSpouseSelected"
          />
          <p
            v-if="pensionDateSpouseIsRequired && isBlankSpouse"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.DATE_SPOUSE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- AMOUNT SPOUSE -->
      <div class="amount-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.AMOUNT_SPOUSE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="amountSpouse"
            type="money"
            :maxlength="11"
            :error="$v.amountSpouse.$invalid && $v.amountSpouse.$dirty"
            :placeholder="$t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.AMOUNT_SPOUSE.PLACEHOLDER')"
            @input="$v.amountSpouse.$touch()"
          />
          <p
            v-if="$v.amountSpouse.$invalid && $v.amountSpouse.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.SPOUSE.AMOUNT_SPOUSE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- DATE CHILDREN -->
      <h3 class="section-title ml-68 mb-33 mt-40 flex-auto text-grey-900 text-18 font-semibold">
        {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.TITLE_CHILDREN') }}
      </h3>
      <div class="date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.DATE_CHILDREN.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker
            :date="pensionDateChildren"
            :is-blank="isBlankChildren"
            @date-selected="pensionDateChildrenSelected"
          />
          <p
            v-if="pensionDateChildrenIsRequired && isBlankChildren"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.DATE_CHILDREN.ERROR') }}
          </p>
        </div>
      </div>

      <!-- AMOUNT CHILDREN -->
      <div class="amount-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.AMOUNT_CHILDREN.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="amountChildren"
            type="money"
            :maxlength="11"
            :error="$v.amountChildren.$invalid && $v.amountChildren.$dirty"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.AMOUNT_CHILDREN.PLACEHOLDER')
            "
            @input="$v.amountChildren.$touch()"
          />
          <p
            v-if="$v.amountChildren.$invalid && $v.amountChildren.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.PENSION.FIELDS.CHILDREN.AMOUNT_CHILDREN.ERROR') }}
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { minLength, requiredIf } from 'vuelidate/lib/validators'
import AppInput from '@/components/form/AppInput'
import AppDatepicker from '@/components/form/AppDatepicker'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PensionForm',
  components: {
    AppInput,
    AppDatepicker,
  },
  mixins: [validationMixin],

  data: () => ({
    integrationName: 'seniorDatosS4VO',
    pensionDateSpouse: undefined,
    amountSpouse: undefined,
    pensionDateChildren: undefined,
    amountChildren: undefined,
    isBlankSpouse: false,
    isBlankChildren: false,
  }),
  computed: {
    ...mapState('personalData', ['assembledPensionData']),
    formName() {
      return this.$t('PERSONAL_DATA.FORMS.PENSION.TITLE')
    },
    canEdit() {
      const canEditFormCase1 = this.status === '2'
      const canEditFormCase2 = this.status === '3'
      const canEditFormCase3 = this.status === null
      return canEditFormCase1 || canEditFormCase2 || canEditFormCase3
    },
    pensionDateSpouseIsRequired() {
      return this.$v.amountSpouse.$dirty
    },
    pensionDateChildrenIsRequired() {
      return this.$v.amountChildren.$dirty
    },
  },
  validations: {
    pensionDateSpouse: {
      required: requiredIf(function () {
        const isValid = this.pensionDateSpouseIsRequired && this.isBlankSpouse
        return isValid
      }),
    },
    amountSpouse: {
      minLength: minLength(1),
    },
    pensionDateChildren: {
      required: requiredIf(function () {
        const isValid = this.pensionDateChildrenIsRequired && this.isBlankSpouse
        return isValid
      }),
    },
    amountChildren: {
      minLength: minLength(1),
    },
  },
  mounted() {
    window.scrollTo(0, 0), this.localAssembler(), this.$nextTick(() => this.$v.$reset())
  },
  beforeDestroy() {
    this.emptyMessages()
  },
  methods: {
    ...mapMutations('personalData', ['setPensionDataFormRawDataToCMS']),
    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),
    localAssembler() {
      this.pensionDateSpouse = this.assembledPensionData.pensionDateSpouse.date
      this.amountSpouse = this.assembledPensionData.amountSpouse
      this.pensionDateChildren = this.assembledPensionData.pensionDateChildren.date
      this.amountChildren = this.assembledPensionData.amountChildren
      this.status = this.assembledPensionData.status

      if (this.pensionDateSpouse === undefined) {
        this.isBlankSpouse = true
      } else {
        this.pensionDateSpouse = this.assembledPensionData.pensionDateSpouse.date
      }

      if (this.pensionDateChildren === undefined) {
        this.isBlankChildren = true
      } else {
        this.pensionDateChildren = this.assembledPensionData.pensionDateChildren.date
      }

      if (!this.canEdit) {
        this.addMessage({
          type: 'success',
          text: 'Ya has grabado datos para esta sección!',
        })
      }
    },
    sendDataToAssembler() {
      this.setPensionDataFormRawDataToCMS({
        pensionDateSpouse: this.pensionDateSpouse,
        amountSpouse: this.amountSpouse,
        pensionDateChildren: this.pensionDateChildren,
        amountChildren: this.amountChildren,
      })
    },
    pensionDateSpouseSelected(date) {
      this.pensionDateSpouse = date
      this.isBlankSpouse = false
      this.$v.pensionDateSpouse.$touch()
    },
    pensionDateChildrenSelected(date) {
      this.pensionDateChildren = date
      this.isBlankChildren = false
      this.$v.pensionDateChildren.$touch()
    },
    save() {
      const formDataResponse = {
        formName: this.formName,
        integrationName: this.integrationName,
        isValid: !this.$v.$invalid,
        canEdit: this.canEdit,
        isAnyDirty: this.$v.$anyDirty,
      }

      this.sendDataToAssembler()
      return formDataResponse
    },
  },
}
</script>
