<template>
  <div class="updated-fields-summaty-and-file-uploader mt-40">
    <h1 class="title pb-12 flex-auto text-grey-900 text-32 font-semibold">
      {{ $t('PERSONAL_DATA.UPDATED_FIELDS_SUMMARY_AND_FILE_UPLOADER.TITLE') }}
      <strong>{{ sectionName }}</strong>
    </h1>
    <p class="description pb-64 text-20">
      {{ $t('PERSONAL_DATA.UPDATED_FIELDS_SUMMARY_AND_FILE_UPLOADER.DESCRIPTION') }}
    </p>
    <component :is="documentsList" />
    <!-- <personal-data-document-list /> -->
    <app-file-uploader
      class="mb-40 mt-38"
      :text="$t('PERSONAL_DATA.FORMS.COMPONENTS.UPLOAD_FILE')"
      @fileSelected="setFile"
    />
    <div class="flex justify-end mb-106">
      <app-button class="text-blue-400" @click="cancelSendFiles">{{
        $t('SHARED.CANCEL')
      }}</app-button>
      <app-button class="bg-blue-500" :disabled="!fileIsAttached" @click="sendFiles">{{
        $t('SHARED.SEND_CHANGES')
      }}</app-button>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'
import AppFileUploader from './AppFileUploader'
import PersonalDataDocumentList from './PersonalDataDocumentsList'
import FamilyDataDocumentList from './FamilyDataDocumentList'
import PensionDataDocumentList from './PensionDataDocumentList'

export default {
  name: 'UpdatedFieldsSummaryAndFileUploader',
  components: {
    AppFileUploader,
    AppButton,
    PersonalDataDocumentList,
    FamilyDataDocumentList,
    PensionDataDocumentList,
  },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    integrationName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    file: undefined,
    fileIsAttached: false,
  }),
  computed: {
    documentsList() {
      const lists = {
        seniorDatosS1VO: PersonalDataDocumentList,
        seniorDatosS3VO: FamilyDataDocumentList,
        seniorDatosS4VO: PensionDataDocumentList,
      }

      return lists[this.integrationName]
    },
  },
  methods: {
    setFile(file) {
      this.file = file
      this.fileIsAttached = true
    },
    cancelSendFiles() {
      this.$emit('cancel')
    },
    sendFiles() {
      if (!this.file) {
        this.fileIsAttached = false
        return
      }
      this.$emit('sendFiles', this.file)
    },
  },
}
</script>
