<template>
  <div class="familiar-form flex flex-col w-full">
    <form class="form mt-33 flex-auto" @submit.prevent>
      <!-- RELATIONSHIP -->
      <div class="relationship-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP.LABEL') }}
          </label>
        </div>
        <div v-if="relationshipOptions" class="w-1/3 max-w-md flex-auto">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP.PLACEHOLDER')"
            :preselected-option="relationship"
            :options="relationshipOptions"
            @select="relationshipSelection"
          />
          <p
            v-if="!relationshipIsValid && $v.relationship.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP.ERRORESPOUSE') }}
          </p>
        </div>
      </div>

      <!-- NAME -->
      <div class="name-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.NAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto placeholder">
          <app-input
            v-model="name"
            :error="$v.name.$invalid && $v.name.$dirty"
            :maxlength="20"
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.NAME.PLACEHOLDER')"
            @input="$v.name.$touch()"
          />
          <p v-if="expressionRegularName" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
          <p
            v-if="$v.name.$invalid && $v.name.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.NAME.ERROR') }}
          </p>
        </div>
      </div>

      <!-- FIRST SURNAME -->
      <div class="first-surname-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.FIRST_SURNAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-input
            v-model="firstSurname"
            :error="$v.firstSurname.$invalid && $v.firstSurname.$dirty"
            :maxlength="20"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.FIRST_SURNAME.PLACEHOLDER')
            "
            @input="$v.firstSurname.$touch()"
          />
          <p
            v-if="$v.firstSurname.$invalid && $v.firstSurname.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.FIRST_SURNAME.ERROR') }}
          </p>
          <p v-if="expressionRegularSurname" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
        </div>
      </div>

      <!-- SECOND SURNAME -->
      <div class="second-surname-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.SECOND_SURNAME.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-input
            v-model="secondSurname"
            :error="$v.secondSurname.$invalid && $v.secondSurname.$dirty"
            :maxlength="20"
            :placeholder="
              $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.SECOND_SURNAME.PLACEHOLDER')
            "
            @input="$v.secondSurname.$touch()"
          />
          <p
            v-if="$v.secondSurname.$invalid && $v.secondSurname.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.SECOND_SURNAME.ERROR') }}
          </p>
          <p v-if="expressionRegularSecondSurname" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.SPECIALCHARACTER') }}
          </p>
        </div>
      </div>

      <!-- GENRE -->
      <div class="genre-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.GENRE.LABEL') }}
          </label>
        </div>
        <div v-if="genreOptions" class="w-1/3 max-w-md flex-auto">
          <app-horizontal-radio-button-group :options="genreOptions" />
          <p v-if="!genreIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.GENRE.ERROR') }}
          </p>
        </div>
      </div>

      <!-- BIRTH_DATE -->
      <div class="birth-date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.BIRTH_DATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-datepicker
            :date="birthDate"
            @date-selected="birthDateSelected"
            @date-is-ok="birthDateOk"
          />
          <p v-if="birhtdateOlderDateToday" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ERROR_DATE_HIGHER_TODAY') }}
          </p>
          <p
            v-if="dateBirthDateHigherRelationship"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.BIRTH_DATE.ERRORHIGHER') }}
          </p>
          <p v-if="sonYounger" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.BIRTH_DATE.ERRORSONYOUNGER') }}
          </p>
          <p v-if="relationshipLessAge" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.BIRTH_DATE.ERRORLESS') }}
          </p>
        </div>
      </div>

      <!-- RELATIONSHIP_START_DATE -->
      <div class="death-date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP_START_DATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-datepicker
            :date="relationshipStartDate"
            :is-blank="isDataRelationshipStartDate"
            @date-is-ok="relationshipDateOk"
            @date-selected="relationshipStartDateSelected"
          />
          <p v-if="ralationshipDateOlderDateToday" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ERROR_DATE_HIGHER_TODAY') }}
          </p>
          <p v-if="dateRelationshipError" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP_START_DATE.ERROR') }}
          </p>
          <p v-if="dateRelationshipLessBirthDate" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.RELATIONSHIP_START_DATE.ERRORDATE') }}
          </p>
        </div>
      </div>
      <!-- DEATH_DATE -->
      <div class="death-date-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.DEATH_DATE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-datepicker
            :date="deathDate"
            :is-blank="isBlank"
            @date-is-ok="deathDateOk"
            @date-selected="deathDateSelected"
          />
          <p v-if="dateDeathDateLessBirthDate" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.DEATH_DATE.ERRORLESS') }}
          </p>
          <p v-if="dateDeathDateLessRelationship" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.DEATH_DATE.ERRORHIGHER') }}
          </p>
          <p v-if="deathDateOlderDateToday" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ERROR_DATE_HIGHER_TODAY') }}
          </p>
        </div>
      </div>

      <!-- ID_CARD -->
      <div class="id-card-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ID_CARD.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto">
          <app-input
            v-model="idCard"
            :error="$v.idCard.$invalid && $v.idCard.$dirty"
            :maxlength="20"
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ID_CARD.PLACEHOLDER')"
            @input="$v.idCard.$touch()"
          />
          <p
            v-if="$v.idCard.$invalid && $v.idCard.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ID_CARD.ERROR') }}
          </p>
          <p
            v-if="ageCalculation && !$v.idCard.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ID_CARD.ERRORAGE') }}
          </p>
        </div>
      </div>
      <!-- CIVILSTATE -->
      <div class="civil-state-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.CIVILSTATE.LABEL') }}
          </label>
        </div>
        <div v-if="civilStateOptions" class="w-1/3 max-w-md flex-auto">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.CIVILSTATE.PLACEHOLDER')"
            :preselected-option="civilState"
            :options="civilStateOptions"
            @select="civilStateSelection"
          />
          <!-- <p v-if="!civilStateIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.CIVILSTATE.ERROR') }}
          </p> -->
        </div>
      </div>

      <!-- ACTUALWORK -->
      <div class="actual-work-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ACTUALWORK.LABEL') }}
          </label>
        </div>
        <div v-if="isWorkingOptions" class="w-1/3 max-w-md flex-auto">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ACTUALWORK.PLACEHOLDER')"
            :preselected-option="isWorking"
            :options="isWorkingOptions"
            @select="isWorkingSelection"
          />
          <!-- <p v-if="!actualWorkIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.ACTUALWORK.ERROR') }}
          </p> -->
        </div>
      </div>

      <!-- COMPUTE -->
      <div class="compute-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.COMPUTE.LABEL') }}
          </label>
        </div>
        <div v-if="isComputingOptions" class="w-1/3 max-w-md flex-auto">
          <app-select
            :placeholder="$t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.COMPUTE.PLACEHOLDER')"
            :preselected-option="isComputing"
            :options="isComputingOptions"
            @select="isComputingSelection"
          />
          <!-- <p v-if="!computeIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.COMPUTE.ERROR') }}
          </p> -->
        </div>
      </div>

      <!-- HANDICAP -->
      <div class="handicap-field form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.HANDICAP.LABEL') }}
          </label>
        </div>
        <div v-if="!!handicap && handicap.values.length" class="w-1/3 max-w-md flex-auto">
          <app-horizontal-radio-button-group :options="handicap.values" />
          <p v-if="!handicapIsValid" class="error-message text-red-500 text-15 mt-12">
            {{ $t('PERSONAL_DATA.FORMS.FAMILY.COMPONENTS.FORM.HANDICAP.ERROR') }}
          </p>
        </div>
      </div>

      <!-- EXTENDED HANDICAP -->
      <div
        v-if="handicap && extendedHandicapIsVisible"
        class="handicap-extended-info bg-white form-row-wrapper p-32 mt-40 rounded-1 ml-68 mb-40 mr-28"
      >
        <!-- PERCENTAGE -->
        <div class="handicap-extended-percentage-field form-row-wrapper md:flex">
          <div class="flex-auto w-1/3 pt-10">
            <label class="text-16 text-gray-900">
              {{
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.LABEL'
                )
              }}
            </label>
          </div>
          <div class="w-1/3 max-w-md flex-auto">
            <app-input
              v-model.number="percentage"
              type="number"
              class="bg-white"
              :maxlength="3"
              :placeholder="
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.PLACEHOLDER'
                )
              "
              @input="$v.percentage.$touch()"
            />
            <p v-if="$v.percentage.$invalid" class="error-message text-red-500 text-15 mt-12">
              {{
                $t(
                  'PERSONAL_DATA.FORMS.PERSONAL_DATA.FIELDS.HANDICAP.EXTENDED_INFO.PERCENTAGE.ERROR'
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <app-button
        type="submit"
        class="text-15 font-medium leading-160 text-center text-white border-none py-16 px-32 rounded-1 bg-blue-500"
        @click="addNewFamiliar()"
      >
        {{ $t('PERSONAL_DATA.FORMS.FAMILY.ADD_NEW_FAMILIAR') }}
      </app-button>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  requiredIf,
  maxLength,
  between,
  numeric,
} from 'vuelidate/lib/validators'
import ConfirmSave from '@/components/modals/ConfirmSave'
import AppInput from '@/components/form/AppInput'
import AppSelect from '@/components/form/AppSelect'
import AppHorizontalRadioButtonGroup from '@/components/form/AppHorizontalRadioButtonGroup'
import AppDatepicker from '@/components/form/AppDatepicker'
import AppButton from '@/components/AppButton'
import familyDataAssembler from '../../helpers/familyDataAssembler'
import { spanishIdCards } from '@/shared/utils/idCardsValidator'

const idCardValidator = (idCard) => spanishIdCards(idCard)

export default {
  name: 'FamiliarForm',
  components: {
    AppInput,
    AppSelect,
    AppHorizontalRadioButtonGroup,
    AppDatepicker,
    AppButton,
  },
  mixins: [validationMixin],
  props: {
    seniorBirthDate: {
      type: Object,
      required: false,
    },
    seniorCivilState: {
      type: String,
      required: false,
    },
    family: {
      type: Array,
      required: false,
    },
    familiar: {
      type: Object,
      required: false,
    },
    isNewFamiliar: {
      type: Boolean,
      required: true,
    },
    masterRelationships: {
      type: Array,
      required: false,
    },
    masterGenres: {
      type: Array,
      required: false,
    },
    masterCivilStates: {
      type: Array,
      required: false,
    },
    masterWorkCategories: {
      type: Array,
      required: false,
    },
    masterIsComputing: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    familiarNumber: undefined,
    relationship: undefined,
    relationshipOptions: undefined,
    name: '',
    firstSurname: '',
    secondSurname: '',
    genreOptions: undefined,
    birthDate: undefined,
    relationshipStartDate: undefined,
    deathDate: undefined,
    idCard: '',
    civilState: undefined,
    civilStateOptions: undefined,
    actualWork: undefined,
    compute: undefined,
    percentage: 0,
    isComputing: undefined,
    isComputingOptions: undefined,
    isWorking: undefined,
    isWorkingOptions: undefined,
    handicap: undefined,
    isBlank: false,
    isDataRelationshipStartDate: true,
    action: undefined,
    birthOK: true,
    relationshipOK: true,
    deathOk: true,
  }),
  computed: {
    relationshipIsValid() {
      const withPartner =
        this.seniorCivilState === 'M' ||
        this.seniorCivilState === 'S' ||
        this.seniorCivilState === 'O'

      if (this.action == 3) {
        if (withPartner && (this.relationship.value === 'C' || this.relationship.value === 'K')) {
          return false
        }
        if (this.seniorCivilState === 'J' && this.relationship.value === 'C') return false

        return true
      } else if (this.action == 5) {
        if (this.relationship.value === 'C' || this.relationship.value === 'K') {
          return !this.family.find((familiar) => familiar.relationship.value === ('C' || 'K'))
        }
        return true
      }
      return true
    },
    sonYounger() {
      if (this.relationship.value !== 'C' && this.relationship.value !== 'K') {
        return this.birthDate < this.seniorBirthDate.date
      }
      return false
    },
    ageCalculation() {
      const actualData = new Date()
      const ageOf14 = new Date(
        actualData.getFullYear() - 14,
        actualData.getMonth(),
        actualData.getDate()
      )
      return this.birthDate < ageOf14 ? true : false
    },
    genreIsValid() {
      return !!this.genreOptions.find((option) => option.model === true)
    },
    civilStateIsValid() {
      return !!this.civilState
    },
    isWorkingIsValid() {
      return !!this.isWorking
    },
    isComputingIsValid() {
      return !!this.isComputing
    },
    birthIsValid() {
      return !!this.birthOK
    },
    relationshipDateIsValid() {
      return !!this.relationshipOK
    },
    deathIsValid() {
      return !!this.deathOk
    },
    handicapIsValid() {
      return !!this.handicap.values.find((option) => option.model === true)
    },
    extendedHandicapIsVisible() {
      const isVisible = this.handicap ? this.handicap.values[0].model === true : false
      if (!isVisible) {
        this.percentage = 0 // eslint-disable-line
      }

      return isVisible
    },

    dateRelationshipError() {
      if (this.isDataRelationshipStartDate == true) {
        return true
      } else {
        return false
      }
    },
    dateRelationshipLessBirthDate() {
      const actualDate = new Date().setHours(0, 0, 0, 0)
      if (this.relationshipStartDate < this.birthDate) {
        if (this.relationshipStartDate.setHours(0, 0, 0, 0) >= actualDate) {
          return false
        }
        return true
      }
      return false
    },
    dateDeathDateLessBirthDate() {
      if (this.deathDate < this.birthDate) {
        if (this.deathDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
          return false
        }
        return true
      }
      return false
    },
    dateDeathDateLessRelationship() {
      if (this.deathDate < this.relationshipStartDate) {
        if (this.deathDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
          return false
        }
        return true
      }
      return false
    },
    dateBirthDateHigherRelationship() {
      const actualDate = new Date().setHours(0, 0, 0, 0)
      if (this.birthDate > this.relationshipStartDate) {
        if (this.birthDate.setHours(0, 0, 0, 0) > actualDate) {
          return false
        }
        return true
      }
      return false
    },
    birhtdateOlderDateToday() {
      const actualDate = new Date().setHours(0, 0, 0, 0)
      const emptyImput = new Date('2999', '11', '31')

      if (this.birthDate.setHours(0, 0, 0, 0) > actualDate) {
        if (this.birthDate.setHours(0, 0, 0, 0) - emptyImput === 0) {
          return false
        }
        return true
      }
      return false
    },
    ralationshipDateOlderDateToday() {
      const actualDate = new Date().setHours(0, 0, 0, 0)
      const emptyImput = new Date('2999', '11', '31')

      if (this.relationshipStartDate.setHours(0, 0, 0, 0) > actualDate) {
        if (this.relationshipStartDate.setHours(0, 0, 0, 0) - emptyImput === 0) {
          return false
        }
        return true
      }
      return false
    },
    deathDateOlderDateToday() {
      const actualDate = new Date().setHours(0, 0, 0, 0)
      const defaultDeath = new Date('2999', '11', '31')

      if (this.deathDate.setHours(0, 0, 0, 0) > actualDate) {
        if (this.deathDate - defaultDeath === 0) {
          return false
        }
        return true
      }
      return false
    },
    relationshipLessAge() {
      const actualData = new Date()
      const ageOf16 = new Date(
        actualData.getFullYear() - 16,
        actualData.getMonth(),
        actualData.getDate()
      )
      if (this.relationship.value === 'C' || this.relationship.value === 'K') {
        if (this.birthDate > ageOf16) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    expressionRegularName() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.name)
      return res
    },
    expressionRegularSurname() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.firstSurname)
      return res
    },
    expressionRegularSecondSurname() {
      var expression = '[^ABCDEFGHIJKLMNÑOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz .ÜüÇçáéíóúÁÉÍÓÚ-]'
      var format = new RegExp(expression)
      var res = format.test(this.secondSurname)
      return res
    },
    notTrackableFieldsAreValid() {
      return (
        this.genreIsValid &&
        this.handicapIsValid &&
        this.civilStateIsValid &&
        this.isWorkingIsValid &&
        this.isComputingIsValid
      )
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(20),
    },
    firstSurname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(20),
    },
    secondSurname: {
      minLength: minLength(1),
      maxLength: maxLength(20),
    },
    idCard: {
      required: requiredIf(function () {
        return this.ageCalculation
      }),
      minLength: minLength(9),
      maxLength: maxLength(9),
      idCardValidator,
    },
    percentage: {
      minLength: minLength(1),
      maxLength: maxLength(3),
      between: between(0, 100),
      numeric,
    },
    relationship: {},
  },
  mounted() {
    if (this.isNewFamiliar) {
      this.buildEmptyForm()
    } else {
      this.localAssembler()
    }
  },
  methods: {
    buildEmptyForm() {
      const emptyFamiliar = familyDataAssembler.assembleEmptyFamiliar(
        this.masterRelationships,
        this.masterGenres,
        this.masterCivilStates,
        this.masterWorkCategories,
        this.masterIsComputing
      )

      const deepCopyEmptyFamilyData = JSON.parse(JSON.stringify(emptyFamiliar))
      this.familiarNumber = emptyFamiliar.familiarNumber
      this.relationship = emptyFamiliar.relationship
      this.relationshipOptions = emptyFamiliar.relationshipOptions
      this.name = emptyFamiliar.name
      this.firstSurname = emptyFamiliar.firstSurname
      this.secondSurname = emptyFamiliar.secondSurname
      this.genreOptions = deepCopyEmptyFamilyData.genre
      this.birthDate = emptyFamiliar.birthDate.date
      this.relationshipStartDate = emptyFamiliar.relationshipStartDate.date
      this.deathDate = emptyFamiliar.deathDate.date
      this.idCard = emptyFamiliar.idCard
      this.civilState = emptyFamiliar.civilState
      this.civilStateOptions = emptyFamiliar.civilStateOptions
      this.isComputing = emptyFamiliar.isComputing
      this.isComputingOptions = emptyFamiliar.isComputingOptions
      this.isWorking = emptyFamiliar.isWorking
      this.isWorkingOptions = emptyFamiliar.isWorkingOptions
      this.handicap = deepCopyEmptyFamilyData.handicap
      this.isBlank = true
      this.action = emptyFamiliar.action
    },
    localAssembler() {
      const deepCopyFamilyData = JSON.parse(JSON.stringify(this.familiar))
      this.familiarNumber = this.familiar.familiarNumber
      this.relationship = this.familiar.relationship
      this.relationshipOptions = this.familiar.relationshipOptions
      this.name = this.familiar.name
      this.firstSurname = this.familiar.firstSurname
      this.secondSurname = this.inputReplace(this.familiar.secondSurname)
      this.genreOptions = deepCopyFamilyData.genre
      this.idCard = this.familiar.idCard
      this.civilState = this.familiar.civilState
      this.civilStateOptions = this.familiar.civilStateOptions
      this.isComputing = this.familiar.isComputing
      this.isComputingOptions = this.familiar.isComputingOptions
      this.isWorking = this.familiar.isWorking
      this.isWorkingOptions = this.familiar.isWorkingOptions
      this.handicap = deepCopyFamilyData.handicap
      this.percentage = deepCopyFamilyData.handicap.extended.percentage
      this.status = this.familiar.status
      this.action = this.familiar.action
      this.deathDateBuilder()
      this.birthDateBuilder()
      this.relationshipStartDateBuilder()
    },
    inputReplace(secondSurname) {
      if (secondSurname === 'XXX') {
        return secondSurname.replace('XXX', '')
      }
      return secondSurname
    },
    openConfirmSave() {
      const options = {
        maskClass: 'bg-mask',
      }
      this.$modal.open(ConfirmSave, {}, options).then((response) => {
        response.confirm && this.save(true)
      })
    },
    birthDateSelected(date) {
      this.birthDate = date
    },
    birthDateOk(showError) {
      this.birthOK = !showError
    },
    relationshipStartDateSelected(date) {
      this.relationshipStartDate = date
      this.isDataRelationshipStartDate = false
    },
    relationshipDateOk(showError) {
      this.relationshipOK = !showError
    },
    deathDateOk(showError) {
      this.deathOk = !showError
    },
    deathDateSelected(date) {
      this.deathDate = date
    },
    relationshipSelection(selectedOption) {
      this.$v.relationship.$touch()
      this.relationship = selectedOption
    },
    isWorkingSelection(selectedOption) {
      this.isWorking = selectedOption
    },
    civilStateSelection(selectedOption) {
      this.civilState = selectedOption
    },
    isComputingSelection(selectedOption) {
      this.isComputing = selectedOption
    },
    addNewFamiliar() {
      this.$v.$touch()

      if (
        this.$v.$invalid ||
        !this.notTrackableFieldsAreValid ||
        !this.relationshipIsValid ||
        this.haveSpouse ||
        this.dateRelationshipLessBirthDate ||
        this.dateDeathDateLessBirthDate ||
        this.dateDeathDateLessRelationship ||
        this.dateBirthDateHigherRelationship ||
        this.expressionRegular ||
        this.sonYounger ||
        this.expressionRegularName ||
        this.expressionRegularSurname ||
        this.expressionRegularSecondSurname ||
        this.dateRelationshipError ||
        this.relationshipLessAge ||
        this.birhtdateOlderDateToday ||
        this.deathDateOlderDateToday ||
        this.ralationshipDateOlderDateToday ||
        !this.birthIsValid ||
        !this.relationshipDateIsValid ||
        !this.deathIsValid
      ) {
        return
      }
      const familiar = {
        familiarNumber: this.familiarNumber,
        relationship: this.relationship,
        relationshipOptions: this.relationshipOptions,
        name: this.name,
        firstSurname: this.firstSurname,
        secondSurname: this.secondSurname,
        genreOptions: this.genreOptions,
        birthDate: this.birthDate,
        relationshipStartDate: this.relationshipStartDate,
        deathDate: this.deathDate,
        idCard: this.idCard,
        civilState: this.civilState,
        civilStateOptions: this.civilStateOptions,
        isComputing: this.isComputing,
        isComputingOptions: this.isComputingOptions,
        isWorking: this.isWorking,
        isWorkingOptions: this.isWorkingOptions,
        handicap: this.handicapBuilder(this.handicap, this.percentage),
      }
      this.$emit('add-familiar', familyDataAssembler.assembleSingleFamiliarFromForm(familiar))
    },
    handicapBuilder(handicap, percentage) {
      handicap.extended.percentage = percentage

      return handicap
    },
    relationshipStartDateBuilder() {
      const typeofIsNotString = typeof this.familiar.relationshipStartDate.date !== 'string'
      if (typeofIsNotString) {
        this.isDataRelationshipStartDate = false
        this.relationshipStartDate = this.familiar.relationshipStartDate.dateControl
          ? this.familiar.relationshipStartDate.dateControl
          : this.familiar.relationshipStartDate.date
      } else {
        this.isDataRelationshipStartDate = true
        this.relationshipStartDate = new Date()
      }
    },

    deathDateBuilder() {
      const typeofIsNotString = typeof this.familiar.deathDate.date !== 'string'
      if (typeofIsNotString) {
        this.deathDate = this.familiar.deathDate.dateControl
          ? this.familiar.deathDate.dateControl
          : this.familiar.deathDate.date
      } else {
        this.isBlank = true
        this.deathDate = new Date()
      }
    },
    birthDateBuilder() {
      const typeofIsNotString = typeof this.familiar.birthDate.date !== 'string'
      if (typeofIsNotString) {
        this.birthDate = this.familiar.birthDate.dateControl
          ? this.familiar.birthDate.dateControl
          : this.familiar.birthDate.date
      } else {
        this.birthDate = new Date()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .wrapper,
::v-deep .app-input-text {
  // stylelint-disable-next-line color-named
  background-color: white;
}
</style>
