<template>
  <app-section class="personal-data animated fadeIn">
    <spinner v-if="!formsAreVisible && !formIsReadyToSend" />
    <div class="title flex-initial my-32 pb-32 text-46 font-semibold text-grey-900">
      {{ $t('PERSONAL_DATA.TITLE') }}
    </div>
    <div class="description text-20 text-grey-500 pb-12">
      {{ $t('PERSONAL_DATA.DESCRIPTION.TEXT_ONE') }}
    </div>
    <div class="description text-20 text-grey-500 pb-38">
      {{ $t('PERSONAL_DATA.DESCRIPTION.TEXT_TWO') }}
    </div>

    <div class="flex justify-end">
      <a :href="personalDataHref" target="_blank">
        <information :text="$t('INFORMATION.DATA_PROTECTION_LAW')" class="mt-38" />
      </a>
    </div>
    <forms-with-steps v-if="formsAreVisible" ref="forms" :steps="steps" />
    <updated-fields-summary-and-file-uploader
      v-if="formIsReadyToSend"
      :section-name="formData.formName"
      :integration-name="formData.integrationName"
      @sendFiles="addFiles"
      @cancel="cancelUpdateFiles"
    />
    <div v-if="formsAreVisible" class="flex justify-end mb-106 pr-28">
      <app-button class="text-blue-400" @click="goHome()">{{ $t('SHARED.CANCEL') }}</app-button>
      <app-button class="bg-blue-500" @click="setformAsReadyToSend">{{
        $t('SHARED.SEND_CHANGES')
      }}</app-button>
    </div>
  </app-section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import AppButton from '@/components/AppButton'
import AppSection from '@/components/AppSection'
import Information from '@/components/Information'
import FormsWithSteps from '@/components/forms-with-steps/FormsWithSteps'
import PersonalForm from './components/PersonalForm'
import ContactForm from './components/ContactForm'
import FamilyForm from './components/family/FamilyContainer'
import PensionForm from './components/PensionForm'
import WithholdingForm from './components/WithholdingForm'
import UpdatedFieldsSummaryAndFileUploader from './components/updatedFieldsSummaryAndFileUploader/UpdatedFieldsSummaryAndFileUploader'
import ConfirmSave from '@/components/modals/ConfirmSave'
import Alert from '@/components/modals/Alert'
import Spinner from '@/components/Spinner'
import HomeUrl from '@/mixins/HomeUrl'

export default {
  name: 'PersonalData',
  components: {
    AppButton,
    AppSection,
    Information,
    FormsWithSteps,
    UpdatedFieldsSummaryAndFileUploader,
    Spinner,
  },
  mixins: [HomeUrl],
  data: () => ({
    formData: '',
    forms: undefined,
    steps: [
      {
        title: 'PERSONAL_DATA.FORMS.PERSONAL_DATA.TITLE',
        component: PersonalForm,
      },
      {
        title: 'PERSONAL_DATA.FORMS.CONTACT.TITLE',
        component: ContactForm,
      },
      {
        title: 'PERSONAL_DATA.FORMS.FAMILY.TITLE',
        component: FamilyForm,
      },
      {
        title: 'PERSONAL_DATA.FORMS.PENSION.TITLE',
        component: PensionForm,
      },
      {
        title: 'PERSONAL_DATA.FORMS.WITHHOLDING.TITLE',
        component: WithholdingForm,
      },
    ],
    formIsReadyToSend: false,
  }),
  computed: {
    ...mapGetters('auth', ['isDc']),
    ...mapState('personalData', [
      'assembledPersonalData',
      'assembledContactData',
      'assembledPensionData',
      'personalDataFormDataReadyToSendToCMSAssembler',
      'contactDataFormDataReadyToSendToCMSAssembler',
      'status',
    ]),
    formsAreVisible() {
      const hasAssembledData =
        this.assembledPersonalData && this.assembledContactData && this.assembledPensionData
      return hasAssembledData && !this.formIsReadyToSend
    },
    isValid() {
      return this.forms && this.forms.isValid !== false
    },
    personalDataHref() {
      return this.isDc ? '/legal/dc-datos-personales.pdf' : '/legal/senior-datos-personales.pdf'
    },
  },
  watch: {
    formsAreVisible() {
      if (this.formsAreVisible) {
        this.$nextTick(() => {
          this.forms = this.$refs.forms
        })
      }
    },
  },
  mounted() {
    this.fetchAllPersonalData()
  },
  methods: {
    ...mapActions('personalData', ['fetchAllPersonalData', 'updatePersonalData']),
    ...mapMutations('personalData', [
      'setPersonalDataFormDataToCMSStructure',
      'setContactDataFormDataToCMSStructure',
      'setFamilyDataFormDataToCMSStructure',
      'setPensionDataFormDataToCMSStructure',
      'setWithHoldingDataFormDataToCMSStructure',
    ]),
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    cancelUpdateFiles() {
      this.formIsReadyToSend = false
    },
    addFiles(files) {
      const payload = {
        files: files,
        integrationName: this.formData.integrationName,
      }

      if (this.formData.integrationName === 'seniorDatosS1VO') {
        this.setPersonalDataFormDataToCMSStructure(payload)
      } else if (this.formData.integrationName === 'seniorDatosS2VO') {
        this.setContactDataFormDataToCMSStructure(payload)
      } else if (this.formData.integrationName === 'seniorDatosS3VO') {
        this.setFamilyDataFormDataToCMSStructure(payload)
      } else if (this.formData.integrationName === 'seniorDatosS4VO') {
        this.setPensionDataFormDataToCMSStructure(payload)
      } else if (this.formData.integrationName === 'seniorDatosS5VO') {
        this.setWithHoldingDataFormDataToCMSStructure(payload)
      }

      this.openConfirmSave()
    },
    setformAsReadyToSend() {
      const formDataResponse = this.$refs.forms.save()
      this.formData = formDataResponse

      if (!this.formData.isAnyDirty) {
        this.openAnyDirtyModal()
        return
      }

      if (this.formData.formIsOpen) {
        this.openSaveFamiliarModal()
        return
      }

      if (!this.formData.canEdit) {
        this.openFormDissableModal()
        return
      }

      if (!this.formData.isValid) {
        this.openInvalidFormModal()
        return
      }

      const needFiles =
        this.formData.integrationName === 'seniorDatosS1VO' ||
        this.formData.integrationName === 'seniorDatosS3VO' ||
        this.formData.integrationName === 'seniorDatosS4VO'

      if (needFiles) {
        this.formIsReadyToSend = true
      } else {
        this.addFiles(null)
      }
    },
    async openConfirmSave() {
      const options = {
        maskClass: 'bg-mask',
      }

      this.$modal.open(ConfirmSave, {}, options).then(async (response) => {
        if (!response.confirm) {
          return
        }

        this.setRequestInProgressStatus(true)
        await this.updatePersonalData()

        if (this.status.estado === 'OK') {
          await this.fetchAllPersonalData()
          this.openUpdateSuccessModal()
        } else if (this.status.estado === 'KO' && this.status.literalMensaje) {
          this.openUpdateErrorModalMessage(this.status.literalMensaje)
        } else if (this.status.estado === 'KO') {
          this.openUpdateErrorModal()
        }

        this.formIsReadyToSend = false
        this.setRequestInProgressStatus(false)
      })
    },
    openUpdateSuccessModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.SUCCESS_UPDATE_DATA.TITLE',
          text: 'MODALS.SUCCESS_UPDATE_DATA.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
    openUpdateErrorModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.ERROR_UPDATE_DATA.TITLE',
          text: 'MODALS.ERROR_UPDATE_DATA.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
    openUpdateErrorModalMessage(message) {
      const props = {
        translationKeys: {
          text: message,
        },
      }

      this.$modal.open(Alert, props)
    },

    openFormDissableModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.DISABLED_UPDATE_DATA.TITLE',
          text: 'MODALS.DISABLED_UPDATE_DATA.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },

    openInvalidFormModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.INVALID_FORM.TITLE',
          text: 'MODALS.INVALID_FORM.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },

    openAnyDirtyModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.ANY_DIRTY.TITLE',
          text: 'MODALS.ANY_DIRTY.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },

    openSaveFamiliarModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.SAVE_FAMILIAR.TITLE',
          text: 'MODALS.SAVE_FAMILIAR.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
  },
}
</script>
