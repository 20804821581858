<template>
  <div class="app-file-uploader" @click="uploadFile()">
    <information
      :text="text"
      icon="/imgs/download.svg"
      class="information-component cursor-pointer flex justify-end"
    />
    <p class="pt-15 text-15 color-grey-500 flex justify-end">{{ fileName }}</p>
    <input id="fileInput" type="file" @change="onFileChange" />
  </div>
</template>

<script>
import FileUploader from '@/mixins/FileUploader'

export default {
  name: 'AppFileUploader',
  components: {
    Information: () => import('@/components/Information'),
  },
  mixins: [FileUploader],
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    fileName: undefined,
  }),
}
</script>

<style lang="scss" scoped>
.app-file-uploader {
  // stylelint-disable-next-line selector-max-id
  #fileInput {
    visibility: hidden;
  }

  .information-component {
    // stylelint-disable-next-line selector-max-compound-selectors
    ::v-deep .icon {
      transform: rotate(180deg);
    }
  }
}
</style>
